<style>
	.tech-card {
		background: transport;
		margin-bottom: 5px;
	}
	.tech-card .title {
		color: #79f7f1;
		height: 38px;
		line-height: 38px;
		background: url(./techCard/title-line.png) no-repeat left bottom;
		margin-bottom: 14px;
	}
	.tech-card .four-border {
		background: linear-gradient(#79f7f1, #79f7f1) left top,
			linear-gradient(#79f7f1, #79f7f1) left top,
			linear-gradient(#79f7f1, #79f7f1) right top,
			linear-gradient(#79f7f1, #79f7f1) right top,
			linear-gradient(#79f7f1, #79f7f1) left bottom,
			linear-gradient(#79f7f1, #79f7f1) left bottom,
			linear-gradient(#79f7f1, #79f7f1) right bottom,
			linear-gradient(#79f7f1, #79f7f1) right bottom;
		background-repeat: no-repeat;
		background-size: 2px 10px, 10px 2px;
		padding: 2px;
	}
</style>
<template>
	<div class="tech-card">
		<h4 v-show="title!=''" class="title">
			<img src="./techCard/title-left.png" style="width:26px;margin-right:5px" />
			{{title}}
			<img src="./techCard/title-right.png" style="width:31px;margin-top: 18px;float:right">
		</h4>
		<div v-show="hasBody" class="four-border">
			<div style="border: 1px solid #2e3e46;background:#021C1Fcc;	padding: 16px 12px;">
				<slot name="default" />
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "techCard", //科技卡片容器
		props: {
			title: {
				type: String,
				default: "",
			}, //卡片标题
			hasBody: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {};
		},
	};
</script>